import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button } from '@toasttab/buffet-pui-buttons'
import { PAYMENT_METHOD_DOMAIN } from '../PaymentMethodsComponent/PaymentMethodConstants'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useState, useCallback, useEffect } from 'react'
import { useCreatePaymentMethodDomain } from '../../hooks/useCreatePaymentMethodDomain'
import { DomainModalComponent } from './DomainModalComponent/DomainModalComponent'
import {
  useFetchPaymentMethodDomain,
  PaymentMethodDomainType
} from '../../hooks/useFetchPaymentMethodDomain'
import { TableComponent } from '../ReusableComponents/TableComponent/TableComponent'
import { PaymentIcons } from '../ReusableComponents/PaymentIcons/PaymentIcons'
import { TableColumn } from '../ReusableComponents/TableComponent/TableComponentTypes'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  CheckCircleSelectedIcon,
  WarningOutlineIcon,
  AddIcon
} from '@toasttab/buffet-pui-icons'
import { useValidatePaymentMethodDomain } from '../../hooks/useValidatePaymentMethodDomain'
import { VerifyDomainModalComponent } from './VerifyDomainModalComponent/VerifyDomainModalComponent'
import { useDeletePaymentMethodDomain } from '../../hooks/useDeletePaymentMethodDomain'
import { DeleteDomainModalComponent } from './DeleteDomainModalComponent/DeleteDomainModalComponent'

export const DomainComponent = () => {
  const { partner } = useDepotBanquetProps()
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { createPaymentMethodDomain, domainDataCreated } =
    useCreatePaymentMethodDomain(partner)
  const { fetchDomains, pmdList } = useFetchPaymentMethodDomain(partner)
  const {
    validatePaymentMethodDomain,
    validateData,
    validateLoading,
    validateError
  } = useValidatePaymentMethodDomain(partner)

  const { deletePaymentMethodDomain, deleteResponseStatus } =
    useDeletePaymentMethodDomain(partner)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [pmdId, setPmdId] = useState<string>('')
  const [domainModalData, setModalData] =
    useState<PaymentMethodDomainType | null>(null)
  const [deleteDomainModalData, setDeleteDomainModalData] =
    useState<PaymentMethodDomainType | null>(null)

  const domainColumns: TableColumn[] = [
    { heading: 'Domain', dataKey: 'domain' },
    {
      heading: 'Verification',
      dataKey: 'applePay',
      tooltip: `${PAYMENT_METHOD_DOMAIN.VERIFY_TOOLTIP}`,
      isClickable: (row: PaymentMethodDomainType) =>
        row.applePay === 'inactive',
      render: (applePay: string) => (
        <div>{applePay === 'inactive' ? 'Verify now' : 'Verified'}</div>
      )
    },
    {
      heading: 'Enabled payment methods',
      dataKey: 'paymentMethods',
      render: () => (
        <div className='payment-icons-container'>
          <PaymentIcons paymentType='applePay' />
        </div>
      )
    }
  ]

  useEffect(() => {
    if (domainDataCreated) {
      setPmdId(domainDataCreated.id)
    }
    fetchDomains()
  }, [domainDataCreated, fetchDomains])

  useEffect(() => {
    if (deleteResponseStatus === 200) {
      fetchDomains()
      showSuccessSnackBar(
        <>
          <CheckCircleSelectedIcon accessibility='decorative' />{' '}
          {PAYMENT_METHOD_DOMAIN.DOMAIN_DELETED_MESSAGE}
        </>,
        { autoHideDuration: 4000 }
      )
    }
  }, [deleteResponseStatus, fetchDomains, showSuccessSnackBar])

  useEffect(() => {
    if (validateData) {
      fetchDomains()
    }
  }, [validateData, fetchDomains])

  useEffect(() => {
    if (validateLoading) {
      setLoading(true)
    } else {
      if (validateError) {
        if (validateError.status === 404)
          setErrorMessage(PAYMENT_METHOD_DOMAIN.VALIDATE_404_ERROR)
        else setErrorMessage(PAYMENT_METHOD_DOMAIN.VALIDATE_GENERIC_ERROR)
      } else {
        if (validateData) {
          fetchDomains()
          closeModal()
          showSuccessSnackBar(
            <>
              <CheckCircleSelectedIcon accessibility='decorative' />{' '}
              {PAYMENT_METHOD_DOMAIN.VERIFICATION_SUCCESS_MESSAGE}
            </>,
            { autoHideDuration: 4000 }
          )
        }
      }
    }
  }, [
    validateLoading,
    validateError,
    validateData,
    fetchDomains,
    showSuccessSnackBar
  ])

  const closeModal = () => {
    setIsCreateModalOpen(false)
    setIsVerifyModalOpen(false)
    setIsDeleteModalOpen(false)
    setPmdId('')
    setErrorMessage('')
    setModalData(null)
    setLoading(false)
  }

  const openCreateModal = () => {
    setIsCreateModalOpen(true)
    setPmdId('')
    setModalData(null)
  }

  const openVerifyModal = useCallback(
    (rowData: PaymentMethodDomainType) => {
      const domainToVerify = pmdList.find((config) => config.id === rowData.id)
      setPmdId(rowData.id)
      setIsVerifyModalOpen(true)
      setIsCreateModalOpen(false)
      if (domainToVerify) {
        setModalData(domainToVerify)
      }
    },
    [pmdList]
  )

  const createNewDomain = useCallback(
    async (domainName: string) => {
      try {
        const createDomainData = { domain: domainName }
        await createPaymentMethodDomain(createDomainData)
        closeModal()
        showSuccessSnackBar(
          <>
            <CheckCircleSelectedIcon accessibility='decorative' />{' '}
            {PAYMENT_METHOD_DOMAIN.DOMAIN_SUCCESS_MESSAGE}
          </>,
          { autoHideDuration: 4000 }
        )
      } catch (error) {
        showErrorSnackBar(
          <>
            <WarningOutlineIcon accessibility='decorative' />{' '}
            {PAYMENT_METHOD_DOMAIN.DOMAIN_FAILED_MESSAGE}
          </>,
          { autoHideDuration: 4000 }
        )
      }
    },
    [createPaymentMethodDomain, showErrorSnackBar, showSuccessSnackBar]
  )

  const validateDomain = useCallback(
    async (domainName: string) => {
      try {
        const validateDomainData = {
          integrationName: domainName,
          domain: domainName
        }
        await validatePaymentMethodDomain(pmdId, validateDomainData)
      } catch (error) {
        showErrorSnackBar(
          <>
            <WarningOutlineIcon accessibility='decorative' />{' '}
            {PAYMENT_METHOD_DOMAIN.VERIFICATION_FAILED_MESSAGE}
          </>,
          { autoHideDuration: 4000 }
        )
        closeModal()
      }
    },
    [pmdId, showErrorSnackBar, validatePaymentMethodDomain]
  )

  const handleDeleteDomain = (rowData: PaymentMethodDomainType) => {
    setIsDeleteModalOpen(true)
    setDeleteDomainModalData(rowData)
  }

  const deleteDomain = (id: string) => {
    try {
      deletePaymentMethodDomain(id)
    } catch (error) {
      showErrorSnackBar(
        <>
          <WarningOutlineIcon accessibility='decorative' />{' '}
          {PAYMENT_METHOD_DOMAIN.DOMAIN_DELETE_ERROR_MESSAGE}
        </>,
        { autoHideDuration: 4000 }
      )
    } finally {
      closeModal()
    }
  }

  return (
    <div>
      <CardContainer>
        <div className='flex justify-between mb-5'>
          <div>
            <h2 className='type-headline-5 font-bold'>
              {PAYMENT_METHOD_DOMAIN.HEADING}
            </h2>
            <h3 className='type-subhead text-secondary mt-3'>
              {PAYMENT_METHOD_DOMAIN.SUBHEADING}
            </h3>
          </div>
          <Button
            variant='secondary'
            size='sm'
            iconLeft={<AddIcon accessibility='decorative' />}
            onClick={openCreateModal}
          >
            {PAYMENT_METHOD_DOMAIN.BUTTON_TEXT}
          </Button>
        </div>
        <TableComponent
          columns={domainColumns}
          data={pmdList}
          handleCellClick={openVerifyModal}
          showDeleteButton={true}
          onModalButtonClick={handleDeleteDomain}
        />
      </CardContainer>
      <DomainModalComponent
        modalIsOpen={isCreateModalOpen}
        setModalIsOpen={closeModal}
        onSave={createNewDomain}
      />
      <VerifyDomainModalComponent
        modalIsOpen={isVerifyModalOpen}
        setModalIsOpen={closeModal}
        validate={validateDomain}
        validateLoading={loading}
        error={errorMessage}
        modalData={domainModalData}
      />
      <DeleteDomainModalComponent
        modalIsOpen={isDeleteModalOpen}
        setModalIsOpen={closeModal}
        onDelete={deleteDomain}
        deleteData={deleteDomainModalData}
      />
    </div>
  )
}
