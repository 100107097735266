export const PAYMENT_METHOD_CONFIG = {
  HEADING: 'Payment method configurations',
  SUBHEADING:
    'Add and manage your configurations where you want to enable payment methods.',
  BUTTON_TEXT: 'Add a configuration',
  ADD: 'Add configuration',
  EDIT: 'Edit configuration',
  NAME_LABEL: 'Configuration name',
  NAME_PACEHOLDER: 'Enter a configuration name',
  PAYMENT_METHOD_EMPTY_MESSAGE: 'Select atleast one payment method',
  CONFIG_NAME_EMPTY_MESSAGE: 'Please enter a configuration name'
}

export const PAYMENT_METHOD_DOMAIN = {
  HEADING: 'Payment method domains',
  SUBHEADING: 'Add and verify your domain ownership.',
  EDIT_DOMAIN: 'Edit domain for Apple Pay',
  BUTTON_TEXT: 'Add a new domain',
  REGISTER_DOMAIN: 'Register domain for Apple Pay',
  LABEL: 'Enter the name of the domain you want to enable Apple Pay for',
  DOMAIN_PACEHOLDER: 'Enter a domain name',
  DOMAIN_NAME_TOOLTIP:
    'You must create and register a payment method domain to use Apple Pay as a payment method. You must create a payment method domain for every domain and subdomain in your integration. There is no limit on the number of payment method domains you can create.',
  CAPTION:
    'Example: pos.toasttab.com (top-level domain) or tbd.pos.toasttab.com subdomain',
  HOST_INSTRUCTIONS:
    'To accept Apple Pay payments, you must host the verification file at a specified path on your domain. Using your preferred tool, upload the verification file on your server using the path below, and then select the Verify button.',
  CANCEL: 'Cancel',
  VERIFY: 'Verify',
  REGISTER: 'Register',
  REGISTER_INFO:
    'After registering your domain, you need to verify your domain by hosting the domain verification file. Apple Pay is unavailable as a payment method until the domain has been verified.',
  VERIFY_LATER: "I'll do it later",
  GET_VERIFICATION_FILE_LABEL: 'Open the verification file',
  HOST_VERIFICATION_FILE_LABEL: 'Host the verification file',
  VALIDATE_404_ERROR:
    'We couldn’t verify your domain. We received a 404 status code for your server when attempting to retrieve the file. Please check your logs to see why the request failed and try again.',
  VALIDATE_GENERIC_ERROR:
    'We couldn’t verify your domain. Please check that your file is hosted correctly and try again.',
  UNKNOWN_ERROR: 'Unknown Error. Please try again',
  OPEN_NEW_TAB_BUTTON_LABEL: 'apple-developer-merchantid-domain-association',
  DOMAIN_FAILED_MESSAGE: 'Domain could not be created.',
  DOMAIN_SUCCESS_MESSAGE: 'Domain created.',
  VERIFICATION_SUCCESS_MESSAGE: 'Domain is verified.',
  VERIFICATION_FAILED_MESSAGE: 'Domain could not be verified.',
  INVALID_DOMAIN_MESSAGE: 'Domain name is not valid.',
  VERIFICATION_FILE_INFO_MESSAGE:
    'To accept Apple Pay payments, you must host a static domain verification file at a publicly accessible location on your domain. This file needs to be available on each domain and subdomain used for Apple Pay transactions. You can get the verification file here after registration.',
  DOMAIN_DELETED_MESSAGE: 'domain is deleted',
  DOMAIN_DELETE_ERROR_MESSAGE: 'domain could not be deleted',
  VERIFY_TOOLTIP:
    'Domains are unavailable for use until they have been verified. Select the Verify now link to verify your domain.',
  VERIFY_DOMAIN: 'Verify domain for Apple Pay '
}
